import * as React from 'react';
import { pageStyles, headingStyles, paragraphStyles, headingAccentStyles, linkStyle, listStyles, listItemStyles, listSmallStyles } from '../styles';
const PrivacyPageEn = () => {
	return (
		<main style={pageStyles}>
			<title>Data protection information for the Smart Kitchen Dock app</title>
			<h1 style={headingStyles}>Data protection information for the Smart Kitchen Dock app</h1>
			<div style={paragraphStyles}>
				Home Connect GmbH, located at Carl-Wery-Straße 34, 81739 Munich, Germany (hereinafter "Home Connect" or "We") is the data controller for the
				collection, processing and use of your personal data in connection with the Smart Kitchen Dock App (hereinafter "App"). The Smart Kitchen Dock
				App is only compatible with the Smart Kitchen Dock, i.e. it only works with the Smart Kitchen Dock. It is not interoperable with other devices
				(e.g. other Smart Speakers or docking stations).{' '}
			</div>
			<div style={paragraphStyles}>
				We collect, process and use personal data that is either entered by you or otherwise created and processed in the course of using the App, the
				associated home appliances or services offered by Home Connect in accordance with the applicable data protection laws and inform you below about
				the data processing carried out by us on the basis of the usage agreement existing with you or any other legal basis.{' '}
			</div>
			<h2 style={headingAccentStyles}>1. Personal data categories</h2>
			<div style={paragraphStyles}>
				In connection with the use of the Smart Kitchen Dock App, the Smart Kitchen Dock and the Home Appliances connected thereto via the Home Connect
				Services or other services and features offered by Home Connect (hereinafter "Services"), Home Connect collects, processes and uses the
				following categories of Personal Data:{' '}
			</div>
			<h3 style={headingStyles}>a. Installation data, usage data, </h3>
			<div style={paragraphStyles}>Data that we collect and use in connection with the use of user accounts and the setup of the app are: </div>
			<ul style={listStyles}>
				<li style={listItemStyles}>
					<h4 style={headingStyles}>Use of location data of the terminal device for setup and operation</h4>
					<div style={paragraphStyles}>
						As part of the setup of the Smart Kitchen Dock and App, we use the location data to scan the Bluetooth Low Energy (BLE) signals needed
						to set up and connect the Dock and App to the user's end device in use. In addition to initially establishing the connection, this is
						also used for functionality, where the user places the device into the Smart Kitchen Dock thereby causing the Smart Kitchen Dock App to
						launch automatically. This data is only used for direct use within the applications and is neither stored within the Smart Kitchen Dock
						app or the Smart Kitchen Dock nor transmitted to systems outside these applications.{' '}
					</div>
				</li>
				<li style={listItemStyles}>
					<h4 style={headingStyles}>Use of location data from the network (during setup)</h4>
					<div style={paragraphStyles}>
						The transmission of location data from the network you are connected to is required to display the correct local time on the Smart
						Kitchen Dock App application and to show you the content shared for your location (e.g., Trending Topics) for display on the Home Screen
						of the Smart Kitchen Dock App, as well as to display the correct Home Connect customer service contact information based on the user's
						country. This network IP location is performed once during each setup (e.g. again after a factory reset) within Smart Kitchen Dock app.
						and uses for this purpose the IP address once in the context of a data transmission to a service provider in order to be able to store
						the country code.{' '}
					</div>
				</li>
				<li style={listItemStyles}>
					<div style={paragraphStyles}>
						Information that you provide as part of the connection with the Home Connect as well as an Amazon Alexa user account, although this is
						not stored in the device, but via a token transmitted by the respective provider (for these user accounts, the separate terms of use and
						privacy information of the respective providers apply in each case).{' '}
					</div>
				</li>
				<li style={listItemStyles}>
					<div style={listSmallStyles}>Information that we collect and temporarily use in connection with the ongoing use of the app: </div>
					<ul style={listStyles}>
						<li style={listItemStyles}>Location data (see description above)</li>
					</ul>
				</li>
				<li style={listItemStyles}>
					<div style={listSmallStyles}>Information that we collect and store in connection with the ongoing use of the app: </div>
					<ul style={listStyles}>
						<li style={listItemStyles}>User account settings </li>
						<li style={listItemStyles}>App settings and status of the tracking setting </li>
						<li style={listItemStyles}>
							Interaction with Trending Topics ("Smart Kitchen Dock Inspiration") and "Smart Kitchen Dock Navigation" (e.g. frequency of use)
						</li>
						<li style={listItemStyles}>Interaction with the app's functionalities (e.g. use of which pages/functions in statistical terms).</li>
					</ul>
				</li>
			</ul>

			<h3 style={headingStyles}>b. Connected services, shared use with third parties</h3>
			<div style={paragraphStyles}>
				Once you have set up the Smart Kitchen Dock via the Smart Kitchen Dock app on the mobile device and connected it to your Home Connect account or
				your Alexa account ("connected services"), the functionalities of the Smart Kitchen Dock (e.g. voice control) are provided via an internet
				connection of the Smart Kitchen Dock via the local Wi-Fi network. This is done using the device data as well as the, commands processed via
				Alexa. The control is initially carried out via the app, but the data is not processed directly in the app; instead, it is processed by the
				connected service. Therefore, please refer to the respective privacy information of the connected services.{' '}
			</div>
			<div style={paragraphStyles}>
				Once you have logged in with your Amazon Alexa and your Home Connect user account, the Smart Kitchen Dock will remain connected to your Amazon
				Alexa and your Home Connect user account until you actively disconnect it again via the Smart Kitchen Dock app or you reset the Smart Kitchen
				Dock to factory settings. Removing the mobile device will not disconnect your Amazon Alexa and Home Connect user account. Please refer to the
				notes in the Terms of Use for more information.
			</div>
			<div style={paragraphStyles}>
				If you allow third parties to use the Smart Kitchen Dock, you undertake to inform these third parties prior to use about the purpose and scope
				of the data collection and the further processing and use by the operator and Amazon and to obtain the consent of all users to this data
				handling.
			</div>

			<h3 style={headingStyles}>c. App usage data</h3>
			<div style={paragraphStyles}>
				App usage data is data from your interaction with the app such as app functionalities used, click behavior related to app controls, selections
				in drop-down menus, on/off switch settings and app malfunction reports (if feature available). See also below 6.
			</div>

			<h2 style={headingAccentStyles}>2. Purpose</h2>
			<div style={paragraphStyles}>We use the above categories of data</div>
			<ul style={listStyles}>
				<li style={listItemStyles}>to provide the functionalities of the App as well as the services offered via the App (1.a.-c.),</li>
				<li style={listItemStyles}>
					to improve the user-friendliness of the App and for specific or general troubleshooting in the App and the digital services offered via it
					(1. a to c.),
				</li>
				<li style={listItemStyles}>
					to improve the product and service offerings of the Operator and the Operator's Affiliates, in particular with respect to the Smart Kitchen
					Dock itself and with respect to unused features and other functions of the App and connected home appliances (including algorithmic
					learning) (1. a to c. ),
				</li>
				<li style={listItemStyles}>
					Contacting you in the event of safety notices and product recalls via the Smart Kitchen Dock app (including providing in-app/email messages)
					(1.a.-c.), and
				</li>
				<li style={listItemStyles}>
					for marketing and market research purposes (including the provision of in-app/email messages) (1.a.-c.), insofar and as long as a
					corresponding consent has been given.
				</li>
			</ul>
			<div style={paragraphStyles}>
				The legal bases for the respective processing purposes within the scope of the GDPR (REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT AND OF
				THE COUNCIL of 27 April 2016) can be found in section 12 of this information.
			</div>

			<h2 style={headingAccentStyles}>3. Rule storage periods</h2>
			<div style={paragraphStyles}>Subject to deviating legal requirements, the following standard storage periods shall apply:</div>
			<h3 style={headingStyles}>a. Location data:</h3>
			<div style={paragraphStyles}>
				No permanent storage inside or outside the application, use is only for triggering specific actions as described in 1 a. above.
			</div>
			<h3 style={headingStyles}>b. Usage data:</h3>
			<div style={paragraphStyles}>Deletion with deletion of the local user account or that of the connected user accounts.</div>
			<h3 style={headingStyles}>c. App usage data: </h3>
			<div style={paragraphStyles}>
				Storage in pseudonymous form and provision in personalized form for services/messages provided via the app if the "Allow tracking of usage data"
				function is activated. When the function is deactivated, the individual ID used for tracking is reset so that app usage data that has already
				been collected can no longer be assigned to you.
			</div>

			<h2 style={headingAccentStyles}>4. Control of the data processing</h2>
			<h3 style={headingStyles}>a. Connectivity of your home appliance</h3>
			<div style={paragraphStyles}>
				You can control the connectivity of your home appliance through the app. To use the features provided by the Smart Kitchen Dock and the Smart
				Kitchen Dock App, information (e.g., your user IDs; requests made; and your Home Appliance control instructions) is transmitted via a data
				connection to servers used to process the requests ("Amazon Server") and to carry out your control instructions ("Home Connect Server").
			</div>
			<h3 style={headingStyles}>b. User accounts and local app data</h3>
			<div style={paragraphStyles}>Through the app, you can control your user accounts and delete app data held locally.</div>
			<ul style={listStyles}>
				<li style={listItemStyles}>
					You can reset the Smart Kitchen Dock to the factory default state. In this case, the Smart Kitchen Dock will be unlinked from your Smart
					Kitchen Dock app and your Alexa and Home Connect user account.
				</li>
				<li style={listItemStyles}>By deleting the app, you remove all locally stored user-related data, but not the created accounts.</li>
			</ul>

			<h2 style={headingAccentStyles}>5. Transfer or disclosure of your data, e.g. to third parties</h2>
			<div style={paragraphStyles}>
				To provide the app and the services offered via it, we work together with various service providers. Insofar as we have obligated these service
				providers to process data strictly in accordance with instructions, data processing by these service providers does not require your consent.{' '}
			</div>
			<div style={paragraphStyles}>Service providers that we use as processors:</div>
			<ul style={listStyles}>
				<li style={listItemStyles}>Service provider for hosting services (including for storage)</li>
				<li style={listItemStyles}>Service provider for programming services</li>
				<li style={listItemStyles}>Service provider for hotline services and other app services.</li>
			</ul>
			<div style={paragraphStyles}>
				We only transfer your data to other recipients if this is necessary for the fulfillment of a contract with you or between you and the
				third-party provider, if we or the recipient have a legitimate interest in the transfer of your data, or if you have consented to the transfer.
				These recipients include service providers and also other companies of the group companies associated with the operator. In addition, data may
				be transferred to other recipients if we are required to do so by law or by enforceable official or court order.
			</div>
			<div style={paragraphStyles}>
				Other recipients of your data include, in particular, service providers whose offers and services (third-party services) Smart Kitchen Dock
				mediates within the scope of the app or enables access to such services (see 9. below).
			</div>
			<div style={paragraphStyles}>
				Content, in particular recipes that you have created yourself, may also be transferred to other BSH companies (also internationally) as part of
				technical processing and provision. If we carry out such transfer/processing, this will be done on the basis of agreements under data protection
				law between the BSH companies involved, which also include the application of standard contractual clauses, insofar as the transfer is outside
				the scope of the GDPR and there is no case of Art. 45 GDPR.
			</div>

			<h2 style={headingAccentStyles}>6. App usage recording</h2>
			<div style={paragraphStyles}>
				The app offers the possibility to collect app usage data (see above 1.d.) and uses the following providers for this purpose:
			</div>
			<ul style={listStyles}>
				<li style={listItemStyles}>
					Adobe Analytics from Adobe Systems Software Ireland Limited, 4-6 Riverwalk, Citywest Business Campus, Dublin 24, Republic of Ireland
					(hereinafter "Adobe").
				</li>
			</ul>
			<div style={paragraphStyles}>
				If the "Allow tracking of usage data" function is activated, app usage data will be sent to and stored on servers of the aforementioned
				providers located in the EU. The app usage data enables an analysis of your use of the app and the provision of notifications for services
				provided via the app (see 1.c. above). IP anonymization has been activated for this app, so that the IP address you use is shortened beforehand.
				On behalf of Smart Kitchen Dock, the respective provider will use this information on our behalf to evaluate your use of the App, to generate
				error reports and to compile reports on App activities for Smart Kitchen Dock. The IP address and other personal data transmitted by your mobile
				device as part of the aforementioned services will not be merged with other data from the aforementioned providers or Smart Kitchen Dock without
				your separate consent.
			</div>
			<div style={paragraphStyles}>
				You can control the collection of app usage data (including your IP address) by the aforementioned services, as well as the processing of this
				data by the aforementioned services, by activating or deactivating the "Allow tracking of usage data" function. Depending on the legal situation
				in your country, the function "Send app usage data anonymously for service improvement" may be active by default.
			</div>
			<div style={paragraphStyles}>
				In addition, if you agree, incident reports from the app may be sent to us so that we can take specific action to resolve such incidents.
			</div>

			<h2 style={headingAccentStyles}>7. Data security</h2>
			<div style={paragraphStyles}>
				In order to protect your data from manipulation, loss and unauthorized access by third parties, for example, we use technical and organizational
				measures. These measures include the use of encryption technology, certificates, the use of a firewall on the Smart Kitchen Dock server and
				password protection of the Smart Kitchen Dock app. We continuously review and improve our security measures in line with technological progress.
			</div>

			<h2 style={headingAccentStyles}>8. Scope of the information on data protection</h2>
			<div style={paragraphStyles}>
				This information on data protection applies to the services offered by Smart Kitchen Dock via the app. If additional functions, benefits or
				services are offered by Smart Kitchen Dock or a BSH Hausgeräte Group company within the app, special information on data protection will be
				provided for them, insofar as their use is subject to special data protection regulations.
			</div>
			<div style={paragraphStyles}>
				This information on data protection does not apply to third-party services, even if Smart Kitchen Dock arranges the use of or access to these
				third-party services within the framework of the app (for third-party services, see also the Terms of Use). In this case, the use of these
				third-party services is governed by the respective data protection provisions of the service provider and, if applicable, additional information
				on data protection on our part, which addresses the specifics of these third-party services and is only relevant to this extent.
			</div>
			<div style={paragraphStyles}>
				In the event of onward transfer to another service provider, Smart Kitchen Dock shall make reasonable and appropriate efforts to identify the
				fact of onward transfer (e.g. when embedding the content of the service provider within the app via inline frames), insofar as the onward
				transfer is not obvious. Obviousness is given, for example, if the Smart Kitchen Dock app is left via a link and the app or website of another
				service provider is called up.
			</div>
			<div style={paragraphStyles}>
				If you are a user within the scope of the GDPR, please note that the use of third-party services may result in your personal data being
				processed in countries outside the scope of the GDPR. In this regard, please refer to the data protection information of the respective
				third-party service provider.
			</div>

			<h2 style={headingAccentStyles}>9. Change of the information on data protection</h2>
			<div style={paragraphStyles}>
				In the course of the further development of the app - among other things due to the implementation of new technologies or the introduction of
				new services - it may become necessary to adapt this information on data protection. Smart Kitchen Dock is entitled to make changes to the
				information on data protection as well as to the special information on data protection for the future: this applies in particular if the
				operator provides further services as part of an update.
			</div>
			<div style={paragraphStyles}>
				Changes to the respective information on data protection, which relate to changes in the contractual relationship (e.g. by changing the services
				or the terms of use), we will notify you together with the notes on the respective changes to the terms of use, so that you are informed in good
				time in each case. Insofar as we adapt the data protection information without changes to the contractual relationship, we will make you aware
				of the changes in an appropriate manner.
			</div>
			<div style={paragraphStyles}>
				Editorial changes to these Terms of Use, i.e. changes that do not affect the contractual relationship, such as the correction of typographical
				errors, will be made without notification.
			</div>

			<h2 style={headingAccentStyles}>10. Rights and contact information</h2>
			<div style={paragraphStyles}>
				If, despite our efforts to ensure that the data is correct and up to date, incorrect information about you is stored, we will correct it at your
				request. If you have given us your consent to the collection, processing and use of your personal data, you can revoke this at any time with
				effect for the future. You can usually exercise the revocation by pressing the corresponding setting within the app, otherwise by using the
				contact options mentioned in the app.
			</div>
			<div style={paragraphStyles}>
				Your personal data will be deleted if you revoke your consent to storage, if knowledge of the personal data is no longer required to fulfill the
				purpose for which it was stored, or if storage is inadmissible for other legal reasons. Please take into account that for technical or
				organizational reasons, there may be an overlap between your revocation and the use of your data, e.g. in the context of a newsletter that has
				already been sent. Data that is required for billing and accounting purposes or is subject to the legal obligation to retain data is not
				affected by this.
			</div>
			<div style={paragraphStyles}>
				If you have any questions about data protection or wish to exercise your rights under data protection law to revoke consent, obtain information,
				correct, delete or block data, please contact us using the contact information provided in the app.
			</div>

			<h2 style={headingAccentStyles}>11. Supplementary information according to the GDPR </h2>
			<h3 style={headingStyles}>a. Legal basis for data processing</h3>
			<div style={paragraphStyles}>We base the following data processing on:</div>
			<ul style={listStyles}>
				<li style={listItemStyles}>
					<div style={listSmallStyles}>a legal permission according to Art. 6 para. 1 lit. b GDPR:</div>
					<ul style={listStyles}>
						<li style={listItemStyles}>
							Provision of the functionalities of the App as well as the services offered via the App by processing the data categories 1.a.-c.
						</li>
						<li style={listItemStyles}>
							Transfer of the data categories according to 1.a.-c. to service providers of third-party services that Smart Kitchen Dock mediates
							in the context of the App (see 9. below).
						</li>
						<li style={listItemStyles}>Elimination of disturbances caused by processing of data categories 1.b. -d.</li>
					</ul>
				</li>
				<li style={listItemStyles}>
					<div style={listSmallStyles}>a legal permission according to Art. 6 para. 1 lit. f GDPR:</div>
					<ul style={listStyles}>
						<li style={listItemStyles}>
							Improving the user experience of the app by processing data categories 1.d. (applies to those jurisdictions that allow the
							collection of app usage data based on legitimate interests. The "Allow tracking of usage data" feature is active by default in this
							case. )
						</li>
						<li style={listItemStyles}>
							Improving our product and service offering, in particular with regard to unused programs or frequently used programs and other
							functions of the app and home device by processing data categories 1.c. and d.
						</li>
					</ul>
				</li>
				<li style={listItemStyles}>
					<div style={listSmallStyles}>Your consent according to Art. 6 para. 1 lit. a GDPR:</div>
				</li>
			</ul>
			<h3 style={headingStyles}>b. Your rights</h3>
			<div style={paragraphStyles}>
				In addition to Section 11, you have the following rights. To exercise your right, please use the contact details provided at the end of this
				information on data protection.
			</div>
			<ul style={listStyles}>
				<li style={listItemStyles}>
					<h4 style={headingStyles}>Your right to information about your data:</h4>
					<div style={paragraphStyles}>Upon request, we will provide you with information about the personal data we have stored about you.</div>
				</li>
				<li style={listItemStyles}>
					<h4 style={headingStyles}>Your right to correct or complete your data:</h4>
					<div style={paragraphStyles}>
						We will correct incorrect personal data if you inform us accordingly. We will complete incomplete data upon your notification if this is
						necessary for the purposeful processing of your data.
					</div>
				</li>
				<li style={listItemStyles}>
					<h4 style={headingStyles}>Your right to have your data deleted:</h4>
					<div style={paragraphStyles}>
						We will delete personal data stored by us upon request. For some data, however, the deletion takes place only after a specified
						retention period, because, for example, the law obliges us in some cases to retain the data or we need the data for the processing of
						the contractual relationship with you.{' '}
					</div>
					<div style={paragraphStyles}>Please also refer to section 11.</div>
				</li>
				<li style={listItemStyles}>
					<h4 style={headingStyles}>Your right to block your data:</h4>
					<div style={paragraphStyles}>
						In certain cases defined by law, we will block your data if you so request. Blocked data will only be processed to a very limited
						extent.
					</div>
				</li>
				<li style={listItemStyles}>
					<h4 style={headingStyles}>Your right to revoke consent:</h4>
					<div style={paragraphStyles}>
						You can revoke your consent to the processing of your data at any time with effect for the future. The lawfulness of the processing of
						your data until the revocation remains unaffected.
					</div>
					<div style={paragraphStyles}>Please also refer to section 11.</div>
				</li>
				<li style={listItemStyles}>
					<h4 style={headingStyles}>Your right to object to the processing of your data:</h4>
					<div style={paragraphStyles}>
						You may object to the processing of your data at any time with effect for the future if we process your data on one of the legal bases
						according to Art. 6 (1) lit. e or f GDPR. In the event of an objection, we will cease processing your data if there are no compelling
						legitimate grounds for further processing. Compelling reasons worthy of protection never exist for us if we process your data for the
						purpose of direct marketing.
					</div>
				</li>
				<li style={listItemStyles}>
					<h4 style={headingStyles}>Your right to data portability:</h4>
					<div style={paragraphStyles}>
						At your request, we can provide you with certain data in a structured, common and machine-readable format.
					</div>
				</li>
				<li style={listItemStyles}>
					<h4 style={headingStyles}>Your right of appeal to the supervisory authority:</h4>
					<div style={paragraphStyles}>
						You can also submit complaints of a data protection nature to a data protection authority. To do so, contact the data protection
						authority responsible for your place of residence or the data protection authority responsible for us below.
					</div>
					<div style={paragraphStyles}>
						Bavarian State Office for Data Protection Supervision,{' '}
						<a style={linkStyle} href="http://www.baylda.de/">
							www.baylda.de
						</a>
						.
					</div>
				</li>
			</ul>
			<h3 style={headingStyles}>c. Disclosure of personal data to recipients outside the EEA</h3>
			<div style={paragraphStyles}>
				If necessary for the provision of the functionalities of the app as well as the services offered via the app, we also disclose personal data to
				recipients that are based outside the EEA in so-called third countries. In this case, we ensure before the transfer that either an adequate
				level of data protection exists at the recipient (e.g. based on an adequacy decision of the EU Commission for the respective country or the
				agreement of so-called EU standard contractual clauses of the European Union with the recipient) or your consent to the transfer has been
				obtained.
			</div>
			<div style={paragraphStyles}>
				You can obtain from us an overview of the recipients in third countries and a copy of the specifically agreed arrangements for ensuring the
				appropriate level of data protection. To do so, use the contact details provided at the end of this information on data protection.
			</div>
			<div style={paragraphStyles}>Please also refer to section 9 for the transfer of personal data when using third-party services.</div>
			<h3 style={headingStyles}>d. Contact details of the data protection officer</h3>
			<div style={paragraphStyles}>
				In all questions on the subject of data protection and the exercise of your rights, you can contact our data protection officer directly via the
				following contact details:
			</div>
			<div>BSH Hausgeräte GmbH</div>
			<div>Datenschutzbeauftragter</div>
			<div>Carl-Wery-Str. 34</div>
			<div>81739 Munich</div>
			<div style={paragraphStyles}>Germany</div>
			<div style={paragraphStyles}>
				<a style={linkStyle} href="mailto:Data-Protection-DE@bshg.com">
					Data-Protection-DE@bshg.com
				</a>
			</div>
		</main>
	);
};

export default PrivacyPageEn;
