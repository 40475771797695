// styles
const pageStyles = {
	color: '#232129',
	padding: '4%',
	fontFamily: '-apple-system, Roboto, sans-serif, serif'
};
const headingStyles = {
	marginTop: 0,
	marginBottom: 24
};
const headingAccentStyles = {
	color: '#1F85DE'
};
const paragraphStyles = {
	marginBottom: 48
};
const codeStyles = {
	color: '#8A6534',
	padding: 4,
	backgroundColor: '#FFF4DB',
	fontSize: '1.25rem',
	borderRadius: 4
};
const listStyles = {
	marginBottom: 24,
	paddingLeft: 0,
	marginLeft: 40
};
const listSmallStyles = {
	marginBottom: 24
};
const listItemStyles = {
	marginBottom: 16
};

const linkStyle = {
	color: '#1F85DE',
	fontWeight: 'bold',
};

const docLinkStyle = {
	...linkStyle,
};

const descriptionStyle = {
	color: '#232129',
	fontSize: 14,
	marginTop: 10,
	marginBottom: 0,
	lineHeight: 1.25
};

const docLink = {
	text: 'Documentation',
	url: 'https://www.gatsbyjs.com/docs/',
	color: '#8954A8'
};

const badgeStyle = {
	color: '#fff',
	backgroundColor: '#088413',
	border: '1px solid #088413',
	fontSize: 11,
	fontWeight: 'bold',
	letterSpacing: 1,
	borderRadius: 4,
	padding: '4px 6px',
	display: 'inline-block',
	position: 'relative',
	top: -2,
	marginLeft: 10,
	lineHeight: 1
};

export { pageStyles, headingAccentStyles, headingStyles, badgeStyle, docLink, docLinkStyle, descriptionStyle, listItemStyles, listStyles, codeStyles, paragraphStyles, linkStyle, listSmallStyles };
